/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { titleize } from 'inflection';
import { Link } from 'ui/components/Common';
import { ScreeningChallengeItem } from 'ui/types';
import { formatDate } from 'ui/utils';
import { itemDisabled } from '../utils';

interface Props {
  item: ScreeningChallengeItem;
}

export const Screening: React.FC<Props> = ({ item }) => {
  const { screenings, screeningUploadEnabled } = item.screeningDetails;
  const disabled = itemDisabled(item);

  const renderAction = () => {
    if (item.status.progress >= item.completionLimit) { return false; }
    return disabled === false;
  };

  return (
    <div className="mt-2 flex flex-col gap-3">
      {screenings.map(screening => (
        <div key={screening.id} className="flex flex-col md:flex-row justify-around gap-3 border border-mid-gray rounded-lg">
          <div className="w-full">{formatDate(screening.activityDate)}</div>
          <div className="w-full">{titleize(screening.kind)}</div>

          {screeningUploadEnabled && (
            <div className="w-full">
              <Link to={screening.attachment!} target="_blank">View Attachment</Link>
            </div>
          )}
        </div>
      ))}

      {renderAction() && (
        <Link
          to={`/screenings/new?challenge_item_id=${item.id}`}
          className="mt-2 mx-auto btn btn-primary"
        >
          Complete {screenings.length === 0 ? 'This' : 'Again'}
        </Link>
      )}
    </div>
  );
};

export default Screening;
