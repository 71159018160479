/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { Client, ChallengeItemType as ItemType, PlanDesignChallengeItem } from 'ui/types';
import { formatDate } from 'ui/utils';
import { Biometric } from './Biometric';
import { Coaching } from './Coaching';
import { ChallengeUser } from './ChallengeUser';
import { FormResponse } from './FormResponse';
import { LinkVisit } from './LinkVisit';
import { Proxy } from './Proxy';
import { ResourceView } from './ResourceView';
import { Screening } from './Screening';

interface Props {
  item: PlanDesignChallengeItem;
  client: Client;
}

export const ItemModal: React.FC<Props> = ({ item, client }) => {
  let Component;
  let props = {};

  if (item.datesStatus !== 'future') {
    switch (item.type) {
      case ItemType.Biometric:
        Component = Biometric;
        break;
      case ItemType.ChallengeUser:
        Component = ChallengeUser;
        props = { vocabs: client.vocab };
        break;
      case ItemType.Coaching:
        Component = Coaching;
        break;
      case ItemType.FormResponse:
        Component = FormResponse;
        break;
      case ItemType.LinkVisit:
        Component = LinkVisit;
        break;
      case ItemType.Proxy:
        Component = Proxy;
        break;
      case ItemType.ResourceView:
        Component = ResourceView;
        break;
      case ItemType.Screening:
        Component = Screening;
        break;
      default:
        break;
    }
  }

  const dates = !!item.startDate && !!item.endDate;

  return (
    <div id={`item-modal-${item.id}`}>
      <div className="w-full text-start mb-0 text-h3 mb-2">{item.longName}</div>
      {dates && <div className="text-start text-sm font-bold mb-3">{formatDate(item.startDate as string)} - {formatDate(item.endDate as string)}</div>}
      <div className="text-start text-sm mb-5 no-twp" dangerouslySetInnerHTML={{ __html: item.sanitizedDescription }} />

      {item.datesStatus === 'past' && 'The recording period for this activity is over.'}
      {item.datesStatus === 'future' && "The recording period for this activity hasn't yet started."}
      {!!Component && (<div className="mt-5 text-center"><Component item={item} {...props} /></div>)}
    </div>
  );
};

export default ItemModal;
