/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { Link } from 'ui/components/Common';
import { ProxyChallengeItem } from 'ui/types';
import { itemDisabled } from '../utils';

interface Props {
  item: ProxyChallengeItem;
}

export const Proxy: React.FC<Props> = ({ item }) => {
  if (itemDisabled(item) || !item.formId) { return null; }

  return (
    <div className="mt-2 flex flex-col gap-3">
      <Link
        to={`/forms/${item.formId}/form_responses/new`}
        className="mt-2 mx-auto btn btn-primary"
      >
        Complete This
      </Link>
    </div>
  );
};

export default Proxy;
