import React from 'react';
import moment from 'moment';
import { inflect } from 'inflection';
import Swal from 'sweetalert2';
import { Course } from 'ui/types';
import { Link } from 'ui/components/Common';
import { categoryImage } from 'ui/utils';
import { Times } from '../Icons';

interface Props {
  course: Course;
}

export const CardModal: React.FC<Props> = ({ course }) => {
  const future = moment(course.registerStartDate).startOf('day').isAfter(moment());
  const past = moment(course.registerEndDate).endOf('day').isBefore(moment());

  const buttonText = () => {
    if (past) { return 'Registration Closed'; }
    if (future) { return 'Not Yet Open'; }
    if (course.enrollment) { return course.enrollment.rejoinable ? 'Join Again' : 'View'; }
    if (course.enrollable) { return 'Join'; }

    return null;
  };

  return (
    <div className="flex flex-col gap-y-5 text-black">
      <div className="flex items-center justify-between">
        <div className="text-title">Course Details</div>

        <div
          role="button"
          tabIndex={0}
          onClick={() => Swal.close()}
          onKeyDown={e => e.key === 'Enter' && Swal.close()}
        >
          <Times width="w-[20px]" height="h-[20px]" stroke="stroke-black" />
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-5">
        <img
          loading="lazy"
          src={course.image || categoryImage(course.category)}
          alt={course.name}
          className="card-image fixed-width rounded-lg"
        />

        <div className="w-full flex flex-col items-start text-start gap-y-3">
          <div className="text-title font-serif">{course.name}</div>
          <div className="text-body">Length: {course.itemsCount} {inflect('Module', course.itemsCount)}</div>
          <div className="text-body capitalize">Category: {course.category === 'other' ? 'General Health' : course.category}</div>
        </div>
      </div>

      <div
        className="no-twp text-left"
        dangerouslySetInnerHTML={{ __html: course.sanitizedDescription }}
      />

      <div className="flex items-center justify-end">
        <Link
          to={course.link}
          disabled={future || past}
          className="btn btn-primary px-20"
        >
          {buttonText()}
        </Link>
      </div>
    </div>
  );
};

export default CardModal;
