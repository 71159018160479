/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { singularize, pluralize, titleize } from 'inflection';
import { Link } from 'ui/components/Common';
import { CardModal as ChallengeModal } from 'ui/components/Challenges/CardModal';
import { CardModal as CourseModal } from 'ui/components/Courses/CardModal';
import { Challenge, Vocabs, ChallengeUserChallengeItem, ChallengeItemChallenge } from 'ui/types';
import { apiClient, getVocab } from 'ui/utils';

interface Props {
  item: ChallengeUserChallengeItem;
  vocabs: Vocabs;
}

const implicitLink = (scopes: string) => {
  switch (scopes) {
    case 'individual': return '/challenges#personal';
    case 'group': return '/challenges#group';
    case 'courses': return '/courses';
    default: return '/challenges';
  }
};

const implicitText = (scopes: string, vocab: string) => {
  switch (scopes) {
    case 'individual': return `View Personal ${vocab}`;
    case 'group': return `View Group ${vocab}`;
    case 'courses': return 'View Courses';
    default: return `View ${vocab}`;
  }
};

export const ChallengeUser: React.FC<Props> = ({ item, vocabs }) => {
  const [data, setData] = useState<{ [key: string]: Challenge }>({});
  const { status, challengeUserChallengeItemDetails: { qualifiedScopes, challenges } } = item;
  const vocab = titleize(pluralize(getVocab('challenge', vocabs)));
  const ReactSwal = withReactContent(Swal);

  const challengeModal = (challenge: Challenge) => {
    ReactSwal.fire({
      html: challenge.type === 'Course' ? <CourseModal course={challenge} /> : <ChallengeModal challenge={challenge} vocab={singularize(vocab)} />,
      showConfirmButton: false,
      showClass: { popup: '' },
      hideClass: { popup: '' },
      customClass: {
        popup: 'w-[95%] md:w-1/2',
      },
    });
  };

  const errorModal = () => {
    Swal.fire({
      title: '',
      html: 'There was an error retrieving this information. Please try again later.',
      icon: 'error',
      confirmButtonText: 'Close',
      animation: false,
      customClass: {
        popup: 'w-[95%] md:w-1/2',
        confirmButton: 'btn btn-primary',
      },
    });
  };

  const fetchData = async (challengeId: number) => {
    await apiClient.get(`challenges/${challengeId}`)
      .then((response) => {
        setData({ ...data, [challengeId]: response.data });
        challengeModal(response.data);
      })
      .catch(() => {
        errorModal();
      });
  };

  const fireModal = async (challengeId: number) => {
    const challenge = data[challengeId] || null;

    if (challenge) {
      challengeModal(challenge);
    } else {
      await fetchData(challengeId);
    }
  };

  const explicitChallenge = (challenge: ChallengeItemChallenge) => (
    <div
      key={challenge.id}
      data-testid={`challenge-${challenge.id}`}
      className="p-3 border border-mid-gray rounded-lg flex flex-col md:flex-row gap-1 md:gap-3 justify-between items-center"
    >
      <div
        role="button"
        tabIndex={0}
        className="w-full md:w-5/6 text-center md:text-start hover:underline hover:cursor-pointer"
        onClick={() => fireModal(challenge.id)}
        onKeyDown={e => e.key === 'Enter' && fireModal(challenge.id)}
      >
        {challenge.name}
      </div>

      <div className="w-full xs:w-1/2 lg:w-1/4 text-center md:text-end">
        <div
          role="button"
          tabIndex={0}
          className="block px-2 btn btn-sm btn-secondary"
          onClick={() => fireModal(challenge.id)}
          onKeyDown={e => e.key === 'Enter' && fireModal(challenge.id)}
        >
          More Info
        </div>
      </div>
    </div>
  );

  const renderProgressLink = () => (
    <Link
      to={`/plan_designs/${item.challengeId}/challenge_items/${item.id}`}
      className="text-body hover:underline"
    >
      View Progress
    </Link>
  );

  return (
    <div className="flex flex-col gap-3">
      {qualifiedScopes !== 'explicit' && (
        <React.Fragment>
          <Link
            to={implicitLink(qualifiedScopes)}
            className="mt-2 mx-auto btn btn-primary"
          >
            {implicitText(qualifiedScopes, vocab)}
          </Link>

          {status.progress > 0 && renderProgressLink()}
        </React.Fragment>
      )}

      {qualifiedScopes === 'explicit' && (
        <React.Fragment>
          {challenges.map((challenge: ChallengeItemChallenge) => explicitChallenge(challenge))}
          {status.progress > 0 && renderProgressLink()}
        </React.Fragment>
      )}
    </div>
  );
};

export default ChallengeUser;
