import React from 'react';
import moment from 'moment';
import { inflect, titleize } from 'inflection';
import Swal from 'sweetalert2';
import { Challenge } from 'ui/types';
import { Link } from 'ui/components/Common';
import { categoryImage } from 'ui/utils';
import { Times } from '../Icons';

interface Props {
  challenge: Challenge;
  vocab: string;
}

export const CardModal: React.FC<Props> = ({ challenge, vocab }) => {
  const inviteable = challenge?.invited && moment().isBefore(moment(challenge.endDate).endOf('day'));
  const future = moment(challenge.registerStartDate).startOf('day').isAfter(moment());
  const past = moment(challenge.registerEndDate).endOf('day').isBefore(moment());

  const buttonText = () => {
    if (past) { return 'Registration Closed'; }
    if (future) { return 'Not Yet Open'; }
    if (challenge.enrollment) { return challenge.enrollment.rejoinable ? 'Join Again' : 'View'; }
    if (challenge.enrollable) { return 'Join'; }

    return null;
  };

  return (
    <div className="flex flex-col gap-y-5 text-black">
      <div className="flex items-center justify-between">
        <div className="text-title">{titleize(vocab)} Details</div>

        <div
          role="button"
          tabIndex={0}
          onClick={() => Swal.close()}
          onKeyDown={e => e.key === 'Enter' && Swal.close()}
        >
          <Times width="w-[20px]" height="h-[20px]" stroke="stroke-black" />
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-5">
        <div>
          <img
            loading="lazy"
            src={challenge.image || categoryImage(challenge.category)}
            alt={challenge.name}
            className="card-image fixed-width rounded-lg"
          />

          {future && challenge.startDate && (
            <div className="text-sm">
              {titleize(`Begins ${moment(challenge.startDate).fromNow()}`)}
            </div>
          )}
        </div>

        <div className="w-full flex flex-col items-start text-start gap-y-3">
          <div className="text-title font-serif">{challenge.name}</div>
          <div className="text-body">Length: {challenge.duration} {inflect('Day', challenge.duration)}</div>
          <div className="text-body capitalize">Category: {challenge.category === 'other' ? 'General Health' : challenge.category}</div>
        </div>
      </div>

      <div
        className="no-twp text-left"
        dangerouslySetInnerHTML={{ __html: challenge.sanitizedDescription }}
      />

      {challenge.itemsCount > 0 && (
        <div className="flex flex-col gap-5 text-left">
          <div className="text-title">Preview</div>
          <div>Here&apos;s what you will work on during this challenge:</div>

          <div className="flex flex-wrap gap-5">
            {challenge.itemsPreview.map(item => (
              <div key={item.id} className="w-5/12 wrapper mb-0 truncate border border-mid-gray shadow-none">
                {item.longName || item.name}
              </div>
            ))}
          </div>
        </div>
      )}

      {inviteable && (
        <div className="inline-flex rounded-full justify-end">
          <Link
            to={`challenges/private_challenges/${challenge.id}/decline`}
            method="post"
            label="Decline Invite"
            className="btn-sm py-9px px-18px btn-secondary border rounded-l-full"
          >
            Decline
          </Link>
          <Link
            to={`challenges/private_challenges/${challenge.id}/accept`}
            method="post"
            label="Accept Invite"
            className="btn-sm py-9px px-18px btn-primary rounded-r-full"
          >
            Accept
          </Link>
        </div>
      )}

      {!challenge.invited && (
        <div className="flex items-center justify-end">
          <Link
            to={challenge.link}
            disabled={future || past}
            className="btn btn-primary px-20"
          >
            {buttonText()}
          </Link>
        </div>
      )}
    </div>
  );
};

export default CardModal;
