/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { Link } from 'ui/components/Common';
import { ResourceViewChallengeItem } from 'ui/types';
import { itemDisabled } from '../utils';

interface Props {
  item: ResourceViewChallengeItem;
}

export const ResourceView: React.FC<Props> = ({ item }) => {
  const renderCompleteLink = () => {
    if (item.recordingHidden || itemDisabled(item)) { return null; }

    return (
      <Link
        to={`/posts/${item.post.split('/').at(-1)}`}
        className="mt-2 mx-auto btn btn-primary"
      >
        View Post
      </Link>
    );
  };

  const renderProgressLink = () => {
    if (item.status.progress === 0) { return null; }

    return (
      <Link
        to={`/plan_designs/${item.challengeId}/challenge_items/${item.id}`}
        className="text-body hover:underline"
      >
        View Progress
      </Link>
    );
  };

  return (
    <div className="mt-2 flex flex-col gap-3">
      {renderCompleteLink()}
      {renderProgressLink()}
    </div>
  );
};

export default ResourceView;
